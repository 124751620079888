<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div v-if="account_type=='merchant'">
      <div class="row">
            <div class="col-12 col-md-6 col-lg" v-for="(item, index) in topStatistics" :key="index">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="w-100 animate__animated animate__fadeInLeft">
                            <h4 class="mb-1 mt-1">
                                <span>{{currencySymbol}}</span>
                                <span data-plugin="counterup">
                                    <countTo :startVal="100" :endVal="parseFloat(item.data)" :decimals="2" :duration="2000"></countTo>
                                </span>
                            </h4>
                            <p class="text-muted mb-0">{{item.label}}</p>
                          </div>
                          <div>
                            <div style="width:80px;" v-if="index==0">
                              <img src="@/assets/images/webapp/deposit.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                            <div style="width:80px;" v-else-if="index==1">
                              <img src="@/assets/images/webapp/money-3.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                            <div style="width:80px;" v-else-if="index==2">
                              <img src="@/assets/images/webapp/money-stack.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
                <div class="card overflow-hidden">
                    <div class="card-body h-100 ">
                      <div class="d-flex align-items-center">
                        <div class="w-100 animate__animated animate__fadeInLeft">
                          <h4 class="mb-1 mt-1">
                              <span data-plugin="counterup">{{transactionFee}}</span>%
                          </h4>
                          <p class="text-muted mb-0">Transaction Fee</p>
                        </div>
                        <div>
                          <div style="width:80px;">
                            <img src="@/assets/images/webapp/money.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          <!-- end col-->
      </div>
      <!-- end row-->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-title p-3 border-bottom">
              <div class="d-flex align-items-center">
                <div class="me-2"><img src="@/assets/images/webapp/deposit.png" height="40" /></div>
                <div>
                  <h4 class="fw-medium font-size-20 text-info mb-0"> Daily Deposits</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Last 30 Days</small>
                </div>
              </div>
            </div>
            
            <div class="card-body">
              <div class="text-center">
                <div
                  class="spinner-border text-secondary my-5"
                  v-if="loading"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-if="!loading">
                <apexchart
                  type="line"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="depositData.chartOptions"
                  :series="depositData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="account_type=='admin'">
      <div class="row">
            <div class="col-12 col-md-6 col-lg" v-for="(item, index) in topStatisticsAdmin" :key="index">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="w-100 animate__animated animate__fadeInLeft">
                            <h4 class="mb-1 mt-1">
                                <span v-if="index!==2">{{currencySymbol}}</span>
                                <span data-plugin="counterup" v-if="index!==2">
                                    <countTo :startVal="1000" :endVal="parseInt(item.data)" :duration="2000"></countTo>
                                </span>
                                <span data-plugin="counterup" v-else>
                                    <countTo :startVal="0" :endVal="parseInt(item.data)" :duration="2000"></countTo>
                                </span>
                            </h4>
                            <p class="text-muted mb-0">{{item.label}}</p>
                          </div>
                          <div>
                            <div style="width:80px;" v-if="index==0">
                              <img src="@/assets/images/webapp/deposit.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                            <div style="width:80px;" v-else-if="index==1">
                              <img src="@/assets/images/webapp/money-stack.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                            <div style="width:80px;" v-else-if="index==2">
                              <img src="@/assets/images/webapp/target-audience.png" class="img-fluid animate__animated animate__fadeInRight" alt /> 
                            </div>
                            
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          <!-- end col-->
      </div>
      <!-- end row-->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-title p-3 border-bottom">
              <div class="d-flex align-items-center">
                <div class="me-2"><img src="@/assets/images/webapp/deposit.png" height="40" /></div>
                <div>
                  <h4 class="fw-medium font-size-20 text-info mb-0">Daily Deposits</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Last 30 Days</small>
                </div>
              </div>
            </div>
            
            <div class="card-body">
              <div class="text-center">
                <div
                  class="spinner-border text-secondary my-5"
                  v-if="loading"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-if="!loading">
                <apexchart
                  type="line"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="depositData.chartOptions"
                  :series="depositData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'animate/loading-168.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import countTo from "vue-count-to";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

/**
 * Settings page
 */
 const PageTitle = "Dashboard"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    countTo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      account_type:'',
      title: '',
      items: [
        {
          text: "OneTouch Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currencySymbol:"₹",
      transactionFee: '-',
      topStatistics:[
        {
          data: 0,
          label:"Total Deposits"
        },
        {
          data: 0,
          label:"Total Deposit Merchant Fee"
        },
        {
          data: 0,
          label:"Total Available Balance (After Merchant Fee)"
        },
      ],
      topStatisticsAdmin:[
        {
          data: 0,
          label:"Total Deposits"
        },
        {
          data: 0,
          label:"Total Profit"
        },
        {
          data: 0,
          label:"Total Merchant"
        },
      ],
      depositData:{
         series: [
          {
            name: "Desktops",
            data: [/* your data values */]
          }
        ],
        chartOptions: {
          chart: {
            type: 'line', // Add the chart type property
          },
          // Rest of your chart options
        },
      }
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    }
  },
  middleware: "authentication",
  async mounted(){
 
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.reload()
    if (this.account_type=='merchant'){
      this.getMerchantData();
    }else{
      console.log("another data")
      this.getAdmin();
    }
   
    
  }, 
  created(){
  },
  methods:{
    getMerchantData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url:  appConfig.APIHost + 'controller/account/getDashboardData',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              const data = {
                'TransactionFee': resData.data.transaction_fee,
                'TotalDeposit': resData.data.total_deposit,
                'MerchantFee': resData.data.merchant_fee,
                'AvailableBalance':resData.data.available_balance
              }
              this.transactionFee = data.TransactionFee
              this.topStatistics=[
                {
                  data: parseFloat(data.TotalDeposit),
                  label:"Total Deposits"
                },
                {
                  data: data.MerchantFee,
                  label:"Total Deposit Merchant Fee"
                },
                {
                  data: data.AvailableBalance,
                  label:"Total Available Balance (After Merchant Fee)"
                }
              ]

              // Define the date range and initialize the arrays
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 30); // 30 days ago
              var dateArray = [];
              var depositAmount = [];
              var merchantFee = [];
              
              // Generate random data for each day within the date range
              for (let i = 0; i < 30; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(currentDate.getDate() + i);
                
                // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
                const formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
                dateArray.push(formattedDate);

                // Generate random deposit amount and merchant fee
                const randomDeposit = Math.floor(Math.random() * 10000).toString();
                const merchantFeePercentage = data.TransactionFee / 100; // 2.5% merchant fee
                const randomFee = Math.floor(parseFloat(randomDeposit) * merchantFeePercentage).toString();
                depositAmount.push(randomDeposit);
                merchantFee.push(randomFee);
              }
              const chart = resData.data.last_30_day_deposit
              if (chart.length > 0) {
                dateArray=[]
                depositAmount=[]
                merchantFee = [];
                chart.forEach(value => {
                
                const originalDate = new Date(value.date);
                const formattedDate = originalDate.toISOString().slice(5, 10);
                  dateArray.push(formattedDate);
                  depositAmount.push(value.daily_deposit)
                  merchantFee.push(value.daily_merchant_fee)
                });
                
                var BankLength = 0
                if (dateArray.length <= 3){
                  BankLength = 30 - dateArray.length
                  for (let i = BankLength; i >= 0; i--) {
                    dateArray.push("");
                    depositAmount.push(0);
                    merchantFee.push(0);
                  }
                }
             }  
             

              var self = this;
              this.depositData = {
                series: [
                  {
                    name: "Total Deposit Amount",
                    type: "column",
                    data: depositAmount,
                  },
                  {
                    name: "Merchant Fee",
                    type: "area",
                    data: merchantFee,
                  },
                ],
                
                chartOptions: {
                  chart: {
                    type: "line",
                    stacked: false,
                    toolbar: {
                      show: false,
                    },
                  },
                  stroke: {
                    width: [0, 2, 4],
                    curve: "smooth",
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "85%",
                      borderRadius: 5,
                    },
                  },
                  colors: ["#04469a", "#46e3c3", "#46e3c3"],
                  fill: {
                    opacity: [0.65, 0.25, 1],
                    gradient: {
                      inverseColors: false,
                      shade: "light",
                      type: "vertical",
                      opacityFrom: 0.85,
                      opacityTo: 0.55,
                      stops: [0, 100, 100, 100],
                    },
                  },
                  labels: dateArray,
                  markers: {
                    size: 0,
                  },

                  yaxis: {
                    title: {
                      text: "Daily Deposits",
                    },
                    min: 0,
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return `${self.currencySymbol} ${y}` ;
                        }
                        return y;
                      },
                    },
                  },
                  grid: {
                    borderColor: "#f1f1f1",
                  },
                },
              };
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getAdmin(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              const data = {
                'TransactionFee': 2.5,
                'TotalDeposit':1288000,
                'TotalMerchant':18,
                'TotalProfit':32200
              }
              this.topStatisticsAdmin=[
                {
                  data: data.TotalDeposit,
                  label:"Total Deposits"
                },
                {
                  data: data.TotalProfit,
                  label:"Total Profit"
                },
                {
                  data: data.TotalMerchant,
                  label:"Total Merchant"
                },
              ]


              // Define the date range and initialize the arrays
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 30); // 30 days ago
              var dateArray = [];
              var depositAmount = [];
              var merchantFee = [];

              // Generate random data for each day within the date range
              for (let i = 0; i < 30; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(currentDate.getDate() + i);
                
                // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
                const formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
                dateArray.push(formattedDate);

                // Generate random deposit amount and merchant fee
                const randomDeposit = Math.floor(Math.random() * 100000).toString();
                const merchantFeePercentage = data.TransactionFee / 100; // 2.5% merchant fee
                const randomFee = Math.floor(parseFloat(randomDeposit) * merchantFeePercentage).toString();
                depositAmount.push(randomDeposit);
                merchantFee.push(randomFee);
              }
              var self = this;
              this.depositData = {
                series: [
                  {
                    name: "Total Deposit",
                    type: "column",
                    data: depositAmount,
                  },
                  {
                    name: "Total Profit",
                    type: "area",
                    data: merchantFee,
                  },
                ],
                
                chartOptions: {
                  chart: {
                    type: "line",
                    stacked: false,
                    toolbar: {
                      show: false,
                    },
                  },
                  stroke: {
                    width: [0, 2, 4],
                    curve: "smooth",
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "85%",
                      borderRadius: 5,
                    },
                  },
                  colors: ["#04469a", "#46e3c3", "#46e3c3"],
                  fill: {
                    opacity: [0.65, 0.25, 1],
                    gradient: {
                      inverseColors: false,
                      shade: "light",
                      type: "vertical",
                      opacityFrom: 0.85,
                      opacityTo: 0.55,
                      stops: [0, 100, 100, 100],
                    },
                  },
                  labels: dateArray,
                  markers: {
                    size: 0,
                  },

                  yaxis: {
                    title: {
                      text: "Daily Deposits",
                    },
                    min: 0,
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return `${self.currencySymbol} ${y.toFixed(0)}` ;
                        }
                        return y;
                      },
                    },
                  },
                  grid: {
                    borderColor: "#f1f1f1",
                  },
                },
              };
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>